import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';


import Inquiry from '../../component/Inquiry';
import Loaction from '../../component/Loaction';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import Information from '../../component/Information';
export default function CreativeServices() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Decorative White Cement</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        {/* <li className="breadcrumb-item"><Link to="/services">Services</Link></li> */}
                        {/* <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li> */}
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Decorative White Cement</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR PRODUCT</h3>
                                <h2 className='heading'>Decorative White Cement</h2>
                                <p>Our Decorative White Cement is designed to enhance the visual appeal of your construction projects, providing a pristine white base that is ideal for artistic and aesthetic applications. Whether you’re creating intricate architectural details or aiming for a smoothly finished surface, our cement offers the perfect blend of beauty and practicality.</p>
                                <p>Not only does it excel in appearance, but our Decorative White Cement is also formulated to ensure long-lasting durability and resistance to weathering. Its versatility makes it suitable for both interior and exterior applications, allowing architects, designers, and homeowners to bring their vision to life with confidence and style.</p>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Creative-Services/white-1.jpg')} height="200px" />
                                            <h4> 1 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Creative-Services/white-2.jpg')} height="200px" />
                                            <h4> 5 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Creative-Services/white-3.jpg')} height="200px" />
                                            <h4> 25 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Creative-Services/white-4.jpg')} height="200px" />
                                            <h4> 50 Kg</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
            <Information />
        </>
    )
}
