import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../component/Header";
import Footer from "../component/Footer";
import About from "../pages/About";
import Team from "../pages/Team";
import Service from "../pages/Service/Service";
import ServiceDetails from "../pages/Service/ServiceDetails";

import GalleryPage from "../pages/gallery/GalleryPage";
import WallPutty from "../pages/Service/WallPutty";
import GypsumPlaster from "../pages/Service/GypsumPlaster";
import TilesAdhesive from "../pages/Service/TilesAdhesive";
import Grout from "../pages/Service/Grout";
import DecorateWhiteCement from "../pages/Service/DecorateWhiteCement";
import PrintingandPOSMSolutions from "../pages/Service/PrintingandPOSMSolutions";
import Contact from "../pages/Contact";
import Loader from "../component/Loader";
import BoardMeeting from "../pages/gallery/BoardMeeting";
import HappyClient from "../pages/gallery/HappyClient";
import Diwali from "../pages/gallery/Diwali";
import EmployeesTraining from "../pages/gallery/EmployeesTraining";
import Employee from '../pages/Employee';
import Distributer from '../pages/Distributer';


export default function Router() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup function to clear the timer
    }, []);
    const [headerData, setHeaderData] = useState([]);
    // const [popularData, setPopularData] = useState([]);
    // const [filters, setFilters] = useState({ dest: [], exp: [], who: [], month: [] });
    // const [isVcard, setIsVcard] = useState(false);

    return (
        <>
            <BrowserRouter>

                <Header setHeaderData={setHeaderData} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    {/* <Route path="/team" element={<Team />} /> */}
                    <Route path="/services" element={<Service />} />
                    <Route path="/wall-putty" element={<WallPutty />} />
                    <Route path="/gypsum-plaster-pop" element={<GypsumPlaster />} />
                    <Route path="/tiles-adhesive" element={<TilesAdhesive />} />
                    <Route path="/grout" element={<Grout />} />
                    <Route path="/decorative-white-cement" element={<DecorateWhiteCement />} />
                    <Route path="/services/printing-and-posm-solutions" element={<PrintingandPOSMSolutions />} />
                    <Route path="/service-detail" element={<ServiceDetails />} />
                    <Route path="/gallery" element={<GalleryPage />} />
                    {/* <Route path="/gallery/:title" element={<GalleryDetail />} /> */}
                    <Route path="/gallery/board-meeting" element={<BoardMeeting />} />
                    <Route path="/gallery/happy-client" element={<HappyClient />} />
                    <Route path="/gallery/diwali" element={<Diwali />} />
                    <Route path="/gallery/employees-training" element={<EmployeesTraining />} />
                    {/* <Route path="/career" element={<Career />} /> */}
                    {/* <Route path="/testimonials" element={<Testimonials />} /> */}
                    {/* <Route path="/clients" element={<Client />} /> */}
                    {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path='/employee' element={<Employee />} />
                    <Route path='/distributorship' element={<Distributer />} />
                    {/* <Route path="/city/:title" element={<City />} />
                    <Route path="/area/:title" element={<Area />} />
                    <Route path="/property/:title" element={<Property />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/details" element={<Details />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/mumbai" element={<Mumbai />} />
                    <Route path="/category/:title" element={<Location headerData={headerData} />} />
                    <Route path="/offices/:title" element={<Offices headerData={headerData} />} />
                    <Route path="/gurgaon" element={<Gurgaon />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:title" element={<BlogDetail />} /> */}
                    {/* <Route path="*" element={<Route404 />} /> */}
                </Routes>
                <Footer headerData={headerData} />
            </BrowserRouter >
        </>
    );
}