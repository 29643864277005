import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import Inquiry from '../../component/Inquiry';
import Loaction from '../../component/Loaction';
import Whychooseus from '../../component/Whychooseus';
export default function Service() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Our Services</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa-solid fa-star-of-life"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                    </ol>
                </nav>
            </section>


            {/* <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'><i class="fa-solid fa-star-of-life"></i> OUR SERVICES</h3>
                        <h2 className='heading'>Let’s Talk Digital Marketing</h2>

                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/consumer-marketing"}>
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/customer-marketing.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Consumer Marketing</h4>
                                    <p className=''>Tailored strategies to engage and captivate target consumer segments.</p>
                                    <Link to='/services/consumer-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/trade-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/trade-marketing.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Trade Marketing</h4>
                                    <p className=''>Collaborative programs to strengthen relationships with trade partners and distributors.</p>
                                    <Link to='/services/trade-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/rural-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/Rural-Marketing.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Rural Marketing</h4>
                                    <p className=''>Solutions designed to address the unique challenges and dynamics of rural consumer segments.</p>
                                    <Link to='/services/rural-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/out-of-home"}>
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/ooh.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Out-of-Home (OOH)</h4>
                                    <p className=''>Strategic placement and execution to maximize brand exposure and reach.</p>
                                    <Link to='/services/out-of-home'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/merchandising">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/Merchandising.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Merchandising</h4>
                                    <p className=''>Comprehensive merchandising solutions to optimize product presentation and visibility.</p>
                                    <Link to='/services/merchandising'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/digital-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/digital-marketing.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Digital Marketing</h4>
                                    <p className=''>Integrated digital strategies to connect with consumers across online channels.</p>
                                    <Link to='/services/digital-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/creative-services">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/creative-service.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Creative Services</h4>
                                    <p className=''>Design solutions that capture attention and communicate brand messaging effectively.</p>
                                    <Link to='/services/creative-services'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/printing-and-posm-solutions">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/printing.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Printing and POSM Solutions</h4>
                                    <p className=''>High-quality printing services for various marketing collateral to support promotional activities and campaigns.</p>
                                    <Link to='/services/printing-and-posm-solutions'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section> */}


            <Whychooseus />
            {/* Contact Us */}

            <Inquiry />
            {/* Country Image section */}
            <Loaction />
        </>
    )
}
