import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }
    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>Contact Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Contact us</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom top padding-left-right '>
                <div className="contact-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-detail">
                                    <h3 className="sub-heading wow fadeInUp">Contact us</h3>
                                    <h2 className='wow fadeInUp heading'>Get in Touch</h2>
                                    <div className='call-to-action'>
                                        <a href='tel:18008897990' className='wow fadeInUp'><i class="fa-solid fa-phone"></i> +91-18008897990</a>

                                        <a href="mailto:info@ambikamaxxpaint.in" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> info@ambikamaxxpaint.in</a>
                                    </div>
                                    <div className='social_media'>
                                        <p>Follow us:</p>
                                        <ul>
                                            <li className='' data-wow-delay="0.1s">
                                                <a href="#" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.2s">
                                                <a href="#" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.3s">
                                                <a href="#" target='_blank' className="twitter"><i className="fa-brands fa-linkedin-in"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.4s">
                                                <a href="#" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.5s">
                                                <a href="#" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form'>
                                        <form action="#" className="contFrm" method="POST">
                                            <Label className='wow fadeInUp' data-wow-delay="0.1s">Your Name*</Label>
                                            <div className="row">
                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
                                                    <input type="text" placeholder="First name" className="inptFld" required />
                                                </div>

                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                                    <input type="text" placeholder="Last name" className="inptFld" required />
                                                </div>
                                            </div>

                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="email" placeholder="example@gmail.com" className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Mobile*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="text" placeholder="Mobile..." className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.6s">Your Message*</Label>
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.7s">
                                                    <textarea className="inptFld" rows="5" cols="" placeholder="Your Message..." required></textarea>
                                                </div>
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className=' padding-left-right  location-section'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'><i class="fa-solid fa-star-of-life"></i> ALL LOCATION</h3>
                        <h2 className='heading'>Our Nationwide Presence</h2>

                    </div>
                    <div className='row mb-40px justify-content-center'>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/gurugram.jpg')} />
                                <div className='location_content'>
                                    <h4>Gurgaon</h4>
                                    <ul>
                                        <li><i class="fa-solid fa-location-pin"></i> <p>Enkay Tower, Tower A, FF, Udyog Vihar, Gurgaon-122002</p></li>
                                        <li><i class="fa-solid fa-user"></i> <p>Ishani Ghosh</p></li>
                                        <li><a href='tel:9811304890'><i class="fa-solid fa-phone"></i><p>9811304890</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/banglore.jpg')} />
                                <div className='location_content'>
                                    <h4>Bengaluru </h4>
                                    <ul>
                                        <li><i class="fa-solid fa-location-pin"></i> <p>Umiya Emporim 102, Hosur Main Road, Kaveri Layout, Adugodi, Bengaluru, Karnataka 560029</p></li>
                                        <li><i class="fa-solid fa-user"></i> <p>Mayureasan Gurukkal </p></li>
                                        <li><a href='tel:9740140476'><i class="fa-solid fa-phone"></i><p>9740140476</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/mumbai.jpg')} />
                                <div className='location_content'>
                                    <h4>Mumbai</h4>
                                    <ul>
                                        <li><i class="fa-solid fa-location-pin"></i> <p>Co works, 3rd floor, Times Square, Western Express Highway Andheri East, Mumbai-400069 </p></li>
                                        <li><i class="fa-solid fa-user"></i> <p>Karishma Duthia </p></li>
                                        <li><a href='tel:9867023633'><i class="fa-solid fa-phone"></i><p>9867023633</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/kolkata.jpg')} />
                                <div className='location_content'>
                                    <h4>Kolkata </h4>
                                    <ul>
                                        <li><i class="fa-solid fa-location-pin"></i> <p>Brand Street Work bravo 6 Little Russell Street, 5th floor, Kankaria Estate, Kolkata 700071</p></li>
                                        <li><i class="fa-solid fa-user"></i> <p>Surjit Banik </p></li>
                                        <li><a href='tel:7003876508'><i class="fa-solid fa-phone"></i><p>7003876508</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/chennai.jpg')} />
                                <div className='location_content'>
                                    <h4>Chennai</h4>
                                    <ul>
                                        <li><i class="fa-solid fa-user"></i> <p>E Prasad </p></li>
                                        <li><a href='tel:9790086082'><i class="fa-solid fa-phone"></i><p>9790086082</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/hyderabad.jpg')} />
                                <div className='location_content'>
                                    <h4>Hyderabad</h4>
                                    <ul>
                                        <li><i class="fa-solid fa-user"></i> <p>Odapally Sharath Kumar</p></li>
                                        <li><a href='tel:8074248403'><i class="fa-solid fa-phone"></i><p>8074248403</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/pune.jpg')} />
                                <div className='location_content'>
                                    <h4>Pune</h4>
                                    

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/coimbatore.jpg')} />
                                <div className='location_content'>
                                    <h4>Coimbatore</h4>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section> */}

        </>
    )
}
