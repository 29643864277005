import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'

export default function Contact() {

    const [file, setFile] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    const handleImgChange = (file) => {
        setFile(file);
    }

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>DISTRIBUTORSHIP APPLICATION FORM</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Distributorship Form</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom top padding-left-right '>
                <div className="contact-sec">
                    <div className="container">
                        <center><h2>AMBIKA MAXX INDIA PVT. LTD. </h2>
                            <h3 className="sub-heading wow fadeInUp">Office No. ,3rd Floor, Saran Chamber 1, Park Road, Hazratgunj, Lucknow 226001</h3>
                        </center>
                        <div className="row">
                            {/* <div className="col-lg-6">
                                <div className="contact-detail">
                                    <h3 className="sub-heading wow fadeInUp">Contact us</h3>
                                    <h2 className='wow fadeInUp heading'>Get in Touch</h2>
                                    <div className='call-to-action'>
                                        <a href='tel:915757244789' className='wow fadeInUp'><i class="fa-solid fa-phone"></i> +91-575-724-4789</a>

                                        <a href="mailto:hi@thebrandstreet.co" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> hi@thebrandstreet.co</a>
                                    </div>
                                    <div className='social_media'>
                                        <p>Follow us:</p>
                                        <ul>
                                            <li className='' data-wow-delay="0.1s">
                                                <a href="https://www.facebook.com/brandstreetintegrated/" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.2s">
                                                <a href="https://www.instagram.com/brandstreetlive/?igsh=MWtwN2s2NGdrb3ltMA%3D%3D" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.3s">
                                                <a href="https://www.linkedin.com/company/brandstreet-integrated?originalSubdomain=in" target='_blank' className="twitter"><i className="fa-brands fa-linkedin-in"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.4s">
                                                <a href="https://twitter.com/BrandStreetInt1" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.5s">
                                                <a href="https://www.youtube.com/channel/UCs9vDqDgv7CzMkZa6mJ1ZxA" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form' style={{ backgroundColor: 'white' }}>
                                        {/* <form action="#" className="contFrm" method="POST">
                                            <Label className='wow fadeInUp' data-wow-delay="0.1s">Your Name*</Label>
                                            <div className="row">
                                                <div className="col-sm-3 wow fadeInUp" data-wow-delay="0.2s">
                                                    <input type="text" placeholder="First name" className="inptFld" required />
                                                </div>

                                                <div className="col-sm-3 wow fadeInUp" data-wow-delay="0.3s">
                                                    <input type="text" placeholder="Last name" className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Mobile</Label>
                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="text" placeholder="Enter mobile number" className="inptFld" required />
                                                </div>
                                            </div>

                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-4 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="email" placeholder="example@gmail.com" className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.6s">Your Message*</Label>
                                                <div className="col-4 wow fadeInUp" data-wow-delay="0.7s">
                                                    <textarea className="inptFld" rows="5" cols="" placeholder="Your Message..." required></textarea>
                                                </div>
                                                <div className="col-6 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                                </div>
                                            </div>
                                        </form> */}
                                        <AvForm>
                                            {/* Persional Information */}
                                            <div className='row'>
                                                <h2 style={{ color: 'rgb(23 156 255)' }}>Employee Personal Information</h2>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        placeholder="Enter first name."
                                                        label="First Name"
                                                        required
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="lname"
                                                        className="form-control"
                                                        placeholder="Enter last name."
                                                        label="Last Name"
                                                        required
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="mobile"
                                                        className="form-control"
                                                        label="Mobile Name"
                                                        placeholder="Enter mobile."
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="dob"
                                                        className="form-control"
                                                        label="Date of Birth"
                                                        type="date"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="father"
                                                        className="form-control"
                                                        label="Father/Husband Name"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="type"
                                                        label="Gander"
                                                        // value={currentData?.type}
                                                        required
                                                        type="select"
                                                    >
                                                        <option> Select Gander</option>
                                                        <option value={"top"}> Male</option>
                                                        <option value={"bottom"}> Female</option>
                                                        <option value={"bottom"}> Other</option>
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="marital"
                                                        className="form-control"
                                                        label="Marital Status"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="blood"
                                                        className="form-control"
                                                        label="Blood Group"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="state"
                                                        className="form-control"
                                                        label="Home State"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="blood"
                                                        className="form-control"
                                                        label="Home District"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-8'>
                                                    <AvField
                                                        name="detials"
                                                        className="form-control"
                                                        label="Employee office Details"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="blood"
                                                        className="form-control"
                                                        label="Current Designation"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="office"
                                                        className="form-control"
                                                        label="Current Office"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                            </div>

                                            <div className="offset-md-3 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                                                <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
