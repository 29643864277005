import React from 'react'

const Information = () => {
    return (
        <section>
            <div className="ContactFrame">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-4">
                            <div className="ContactInfo1">
                                <img src="https://winklixfoundation.org/assets/images/phone.png" />
                                <h5>Phone Number</h5>
                                <p>+91 8008897990 <br /> +91 18008897990 </p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="ContactInfo1">
                                <img src="https://winklixfoundation.org/assets/images/mail.png" />
                                <h5>Email &amp; Web</h5>
                                <p>info@ambikamaxxpaint.in <br />ambikamaxxpaint.in</p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="ContactInfo1">
                                <img src="https://winklixfoundation.org/assets/images/map-marker.png" />
                                <h5>Our Address</h5>
                                <p>Saran Chamber1, Park Road, Hazratganj <br />  Lucknow Uttar Pradesh </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Information