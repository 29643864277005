
import React, { useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Inquiry from '../../component/Inquiry'
import Loaction from '../../component/Loaction'
import { Link } from 'react-router-dom'



export default function EmployeesTraining() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }
    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>Gallery</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" style={style}></i></li>
                        <li className="breadcrumb-item"><Link to={"/gallery"} style={style}>Gallery</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" style={style}></i></li>
                        <li className="breadcrumb-item active" style={style}>Celebration</li>
                    </ol>
                </nav>
            </section>
            <section className='padding-left-right top '>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                >
                    <Masonry>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/celebration/1.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/celebration/4.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/celebration/3.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/celebration/6.jpg')} />
                            </div>
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
            </section>
            <section>
                <div class="ContactFrame">
                    <div class="container">

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="ContactInfo1">
                                    <img src="https://winklixfoundation.org/assets/images/phone.png" />
                                    <h5>Phone Number</h5>
                                    <p>+91 8008897990 <br /> +91 18008897990 </p>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="ContactInfo1">
                                    <img src="https://winklixfoundation.org/assets/images/mail.png" />
                                    <h5>Email &amp; Web</h5>
                                    <p>info@ambikamaxxpaint.in <br />ambikamaxxpaint.in</p>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="ContactInfo1">
                                    <img src="https://winklixfoundation.org/assets/images/map-marker.png" />
                                    <h5>Our Address</h5>
                                    <p>Saran Chamber1, Park Road, Hazratganj <br />  Lucknow Uttar Pradesh </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
