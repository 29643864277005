import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';


import Inquiry from '../../component/Inquiry';
import Loaction from '../../component/Loaction';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import Information from '../../component/Information';
export default function DigitalMarketing() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Grout</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Grout</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> OUR PRODUCT</h3>
                                <h2 className='heading'>Grout</h2>
                                <p>Our Grout is formulated to provide strong, long-lasting joints between tiles, preventing moisture penetration and enhancing the overall durability of your tiled surfaces. Available in both 1 kg and 5 kg packages, it’s perfect for projects of any size, from quick repairs to larger renovations.</p>
                                <p>This product is easy to mix and apply, offering a smooth finish that complements any tile color and texture. Its rapid setting time ensures that your tiling projects are completed efficiently without sacrificing quality. Ideal for both indoor and outdoor applications, our Grout helps you achieve seamless aesthetics while maintaining the highest standards of construction.</p>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/grout-2.webp')} height="200px" />
                                            <h4> 1 Kg</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/grout-2.webp')} height="200px" />
                                            <h4> 5 Kg</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>
            <Information />
        </>
    )
}
