
import React, { useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Inquiry from '../../component/Inquiry'
import Loaction from '../../component/Loaction'
import { Link } from 'react-router-dom'
import Information from '../../component/Information'



export default function Christmas() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>Gallery</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item"><Link to={"/gallery"} style={style}>Gallery</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" style={style}>Happy Clients</li>
                    </ol>
                </nav>
            </section>
            <section className='padding-left-right top '>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                >
                    <Masonry>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/happy-client/1.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/happy-client/2.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/happy-client/3.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/happy-client/4.jpg')} />
                            </div>
                        </div>
                        <div className='card_box_gallery'>
                            <div className='gallery-thumb'>
                                <img src={require('../../assets/images/gallery/happy-client/5.jpg')} />
                            </div>
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
            </section>
            <Information />
        </>
    )
}
