import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import Inquiry from '../component/Inquiry';
import Whychooseus from '../component/Whychooseus';
import Loaction from '../component/Loaction';
import { Link } from 'react-router-dom';
import CountPage from '../component/CountPage';
export default function About() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const style = {
        color: 'white',
    }

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>About us</li>
                    </ol>
                </nav>
            </section>

            {/* <section className='top bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 order_2'>
                            <div className='left-section'>
                                <div className='bg_overly'></div>
                                <div className='top-section-box top-section-box-one'>
                                    <div className='circle-box first same_box'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                    <div className='circle-box first same_box1 second'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                </div>
                                <div className='top-section-box top-section-box-two'>

                                    <div className='circle-box first same_box1 three'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                    <div className='circle-box first same_box'>
                                        <img src={require('../assets/images/team.jpg')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 order_1'>
                            <div className='right-section about_content'>
                                <h6 className='sub-heading wow fadeInUp'>BRAND STREET INTEGRATED </h6>
                                <h2 className='heading wow fadeInUp'>Weaving Brand <br />Experience</h2>
                                <p className='paragraph wow fadeInUp'>Brand Street India understands the science and marketing involved between heart and mind of consumer.</p>
                                <p className='paragraph p1 wow fadeInUp'>“We build consumer communities for brands driven by experiences.”</p>
                                <p className='paragraph p1 wow fadeInUp'>So much of who we are is what we experience. Experiential marketing is the best differentiation tool that separates a brand from its competition. Experience builds a memory that leaves an indelible imprint on the customer’s mind.</p>
                                <p className='paragraph p1 wow fadeInUp'>India being a home to diverse set of consumers, demands brands to innovate with every changing region. With the rise in digital wave and information abundance, we sure needed a diverse presence. Our team works to create enduring experiences that deliver long lasting impact of customer engagement and commitment.</p>
                                <p className='paragraph wow fadeInUp'></p>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='top privacy_po padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>
                        <h3 style={{ color: '#00207b' }}><strong>Building Foundations for Tomorrow</strong></h3>
                        <p>
                            Ambika Maxx Paints Pvt. Ltd. is dedicated to manufacturing the highest quality building materials that builders,
                            contractors, and DIY enthusiasts trust. From our state-of-the-art facility in Rajasthan, we produce a variety of essential
                            products including wall putty, decorative white cement, and specialized adhesives.
                            Our commitment to excellence has positioned us as a leader in the building materials industry.
                        </p>
                        <p>As a company, we strive not just to meet but exceed industry standards with every product we offer.
                            Our focus on sustainability guides our practices and product development, ensuring we contribute positively to
                            our environment while delivering unparalleled quality to our customers.
                            Join us in building a stronger, more beautiful future.
                        </p>
                    </div><br />
                    <button type="button" class="btn btn-danger">Contact Us &nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                </div>
            </section>

            <section className='top bottom padding-left-right featured-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='award-img-left'>
                                <img src={require('../assets/images/about-right.jpg')} height='40%' width='100%' alt="service" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='award-right-text'>
                                {/* <h6>Why choose us</h6> */}
                                <h2 className='heading wow fadeInUp' style={{ color: '#00207b' }}>Vision</h2>
                                <p className='paragraph1 wow fadeInUp'>At Ambika Maxx Paints Pvt. Ltd., our vision is to redefine the standard of building materials globally. We aim to lead with innovative products that are recognized for their superior quality and environmental sustainability. By consistently pushing the boundaries of technology and service, we strive to be the trusted choice for builders and homeowners worldwide, contributing to more robust and aesthetically pleasing constructions.</p>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='award-right-text top'>
                                {/* <h6>Why choose us</h6> */}
                                <h2 className='heading wow fadeInUp' style={{ color: '#00207b' }}>Mission</h2>
                                <p className='paragraph1 wow fadeInUp'>Our mission is to deliver unparalleled building solutions that enhance both the infrastructure and the lives of those who use them. Through a relentless focus on quality, customer satisfaction, and eco-friendly practices, we commit to providing our customers with reliable products that support safe and beautiful building environments. We aim to empower our partners and customers with the knowledge and materials to construct durable and sustainable projects.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='award-img-left'>
                                <img src={require('../assets/images/why-choose.jpg')} height='40%' width='100%' alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CountPage />

            <section className='about-section-4 top bottom padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'> OUR TEAMS</h3>
                        <p>Meet the Minds Behind Our Innovation</p><br />
                        <center><div className='blue-line'></div></center>

                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src={require('../assets/images/team/1.jpg')} />
                                    <div className='team-social-media'>
                                    </div>
                                </div>
                                <h4>ARUN KUMAR VISHWAKARMA</h4>
                                <h5>CEO & Founder</h5>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLnX7BzP-A20QL7BGfFOBwpy7fwWW35d6pkw&s" alt="" />
                                    <div className='team-social-media'>
                                    </div>
                                </div>
                                <h4>NEETOO SHARMA</h4>
                                <h5>Co. Founder</h5>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src={require('../assets/images/team/4.jpg')} alt='' />
                                    <div className='team-social-media'>
                                        {/* <li>
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </li> */}
                                    </div>
                                </div>
                                <h4>CHHOTE LAL VISHWAKARMA</h4>
                                <h5>Director</h5>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6'>
                            <div className='team-profile'>
                                <div className='profile-box'>
                                    <img src={require('../assets/images/team/4.jpg')} />
                                    <div className='team-social-media'>
                                        {/* <li>
                                            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </li> */}
                                    </div>
                                </div>
                                <h4>PRADEEP KUMAR</h4>
                                <h5> Director</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className='bottom padding-left-right about-section-2'>
                <div className='container-fluid'>
                    <h3 className='sub-heading'>TRUSTED BY THE BRANDS</h3>
                    <div className='brand-logo'>
                        <Swiper
                            slidesPerView={2.5}
                            spaceBetween={0}
                            navigation={false}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                },
                                768: {
                                    slidesPerView: 5.8,
                                    spaceBetween: 0,
                                },
                                1024: {
                                    slidesPerView: 6,
                                    spaceBetween: 0,
                                },
                            }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}

                            modules={[Autoplay]}

                            className="brand_logo-slider"
                        >
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-1.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-2.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-3.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-4.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-5.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-6.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-7.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-8.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-9.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='logo-box'>
                                    <img src={require('../assets/images/clients/client-10.jpg')} alt='delhi' />

                                </div>
                            </SwiperSlide>


                        </Swiper>
                    </div>
                </div>
            </section> */}


            {/* Faq  section*/}
            {/* <section className=' faq-section-b  featured-section mb-0'>
                <div className='faq-section-bo top bottom padding-left-right '>
                    <div className='container-fluid'>
                        <div className='header-title mb-40px'>
                            <h6 className='sub-heading wow fadeInUp'>AWESOME PORTFOLIO</h6>
                            <h2 className='heading wow fadeInUp'>FAQ's</h2>

                        </div>

                        <div class="accordion wow fadeInUp" id="accordionExample">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='faq-box'>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    What services does Brand Street Integrated offer?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    We provide a comprehensive range of marketing services including consumer, trade, digital, and creative solutions.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How can Brand Street help my brand stand out in a crowded market?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    Through innovative experiential marketing strategies tailored to your brand's unique identity and target audience.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    What industries does Brand Street specialize in serving?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    We have experience across various sectors including retail, technology, hospitality, healthcare, and entertainment.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-6 faq-two-section'>
                                    <div className='faq-box'>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                    Can Brand Street help with marketing to rural areas?

                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    Yes, we offer specialized rural marketing solutions to effectively reach and engage rural consumer segments.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    What is the process for collaborating with Brand Street on a marketing campaign?

                                                </button>
                                            </h2>
                                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    It begins with a consultation to understand your objectives, followed by strategic planning and execution phases.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    How does Brand Street integrate digital marketing into its services?
                                                </button>
                                            </h2>
                                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    By leveraging data-driven insights and innovative digital strategies to enhance brand visibility and engagement online.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}
