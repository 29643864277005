import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'
import Information from '../component/Information';

export default function Contact() {

    const [file, setFile] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }

    const handleImgChange = (file) => {
        setFile(file);
    }

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading' style={style}>Employee Form</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Employee Form</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom top padding-left-right '>
                <div className="contact-sec">
                    <div className="container">
                        <center><h2>AMBIKA MAXX INDIA PVT. LTD. </h2>
                            <h3 className="sub-heading wow fadeInUp">Office No. ,3rd Floor, Saran Chamber 1, Park Road, Hazratgunj, Lucknow 226001</h3>
                        </center>
                        <div className="row">
                            {/* <div className="col-lg-6">
                                <div className="contact-detail">
                                    <h3 className="sub-heading wow fadeInUp">Contact us</h3>
                                    <h2 className='wow fadeInUp heading'>Get in Touch</h2>
                                    <div className='call-to-action'>
                                        <a href='tel:915757244789' className='wow fadeInUp'><i class="fa-solid fa-phone"></i> +91-575-724-4789</a>

                                        <a href="mailto:hi@thebrandstreet.co" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> hi@thebrandstreet.co</a>
                                    </div>
                                    <div className='social_media'>
                                        <p>Follow us:</p>
                                        <ul>
                                            <li className='' data-wow-delay="0.1s">
                                                <a href="https://www.facebook.com/brandstreetintegrated/" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.2s">
                                                <a href="https://www.instagram.com/brandstreetlive/?igsh=MWtwN2s2NGdrb3ltMA%3D%3D" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.3s">
                                                <a href="https://www.linkedin.com/company/brandstreet-integrated?originalSubdomain=in" target='_blank' className="twitter"><i className="fa-brands fa-linkedin-in"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.4s">
                                                <a href="https://twitter.com/BrandStreetInt1" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
                                            </li>
                                            <li className='' data-wow-delay="0.5s">
                                                <a href="https://www.youtube.com/channel/UCs9vDqDgv7CzMkZa6mJ1ZxA" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form' style={{ backgroundColor: 'white' }}>
                                        {/* <form action="#" className="contFrm" method="POST">
                                            <Label className='wow fadeInUp' data-wow-delay="0.1s">Your Name*</Label>
                                            <div className="row">
                                                <div className="col-sm-3 wow fadeInUp" data-wow-delay="0.2s">
                                                    <input type="text" placeholder="First name" className="inptFld" required />
                                                </div>

                                                <div className="col-sm-3 wow fadeInUp" data-wow-delay="0.3s">
                                                    <input type="text" placeholder="Last name" className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Mobile</Label>
                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="text" placeholder="Enter mobile number" className="inptFld" required />
                                                </div>
                                            </div>

                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-4 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="email" placeholder="example@gmail.com" className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.6s">Your Message*</Label>
                                                <div className="col-4 wow fadeInUp" data-wow-delay="0.7s">
                                                    <textarea className="inptFld" rows="5" cols="" placeholder="Your Message..." required></textarea>
                                                </div>
                                                <div className="col-6 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                                </div>
                                            </div>
                                        </form> */}
                                        <AvForm>
                                            {/* Persional Information */}
                                            <div className='row'>
                                                <h2 style={{ color: 'rgb(23 156 255)' }}>Employee Personal Information</h2>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        placeholder="Enter first name."
                                                        label="First Name"
                                                        required
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="lname"
                                                        className="form-control"
                                                        placeholder="Enter last name."
                                                        label="Last Name"
                                                        required
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="mobile"
                                                        className="form-control"
                                                        label="Mobile Name"
                                                        placeholder="Enter mobile."
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="dob"
                                                        className="form-control"
                                                        label="Date of Birth"
                                                        type="date"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="father"
                                                        className="form-control"
                                                        label="Father/Husband Name"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="type"
                                                        label="Gander"
                                                        // value={currentData?.type}
                                                        required
                                                        type="select"
                                                    >
                                                        <option> Select Gander</option>
                                                        <option value={"top"}> Male</option>
                                                        <option value={"bottom"}> Female</option>
                                                        <option value={"bottom"}> Other</option>
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="marital"
                                                        className="form-control"
                                                        label="Marital Status"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="blood"
                                                        className="form-control"
                                                        label="Blood Group"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="state"
                                                        className="form-control"
                                                        label="Home State"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="blood"
                                                        className="form-control"
                                                        label="Home District"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-8'>
                                                    <AvField
                                                        name="detials"
                                                        className="form-control"
                                                        label="Employee office Details"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="blood"
                                                        className="form-control"
                                                        label="Current Designation"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-6'>
                                                    <AvField
                                                        name="office"
                                                        className="form-control"
                                                        label="Current Office"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                            </div>
                                            {/* Present Address */}
                                            <div className='row'>
                                                <h2 style={{ color: 'rgb(23 156 255)' }}>Present Address Detail</h2>
                                                <div className='col-md-8'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Present Addres"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="State"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="District"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Block"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Panchayat"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Pin Code"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Phone Number"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                            </div>
                                            {/* Permanent Address */}
                                            <div className='row'>
                                                <h2 style={{ color: 'rgb(23 156 255)' }}>Permanent Address Detail</h2>
                                                <div className='col-md-8'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Permanent Address"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="State"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="District"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Block"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Panchayat"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Pin Code"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Phone Number"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                            </div>
                                            {/* Joining Details */}
                                            <div className='row'>
                                                <h2 style={{ color: 'rgb(23 156 255)' }}>Joining Details</h2>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Date of Appointment"
                                                        type="date"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Office Name At the time of initial joining in Dept"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Date of Joining in the Dept"
                                                        type="date"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Initial Designation"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-4'>
                                                    <AvField
                                                        name="fname"
                                                        className="form-control"
                                                        label="Mode of Recruitment"
                                                        type="text"
                                                    >
                                                    </AvField>
                                                </div>
                                                <div className='col-md-10'>
                                                    <AvField
                                                        name="file"
                                                        label="Upload Document(Photo Or PDF files)*"
                                                        type='File'
                                                        accept=".pdf, .png, .jpg, .jpeg"
                                                        // value={file}
                                                        onChange={(e) => handleImgChange(e.target.files[0])}
                                                    // required={currentData ? false : true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="offset-md-3 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                                                <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='privacy_po padding-left-right about-section-1 '>
                <div className='container'>
                    <div className='row'>
                        <h3>
                            Note:-Terms and Condition For Sales Department
                        </h3>

                        <ol>
                            {/* <li> */}
                            <ol>
                                <li>If the reporting manager's business is zero then his salary will be basic or full salary credit. This will be
                                    decided by the company based on his business report and business report of previous 3 months.</li>
                                <li>If the reporting manager gets zero business for three consecutive months then the company can fire you by
                                    giving you basic pay.</li>
                                <li> If the marketing employee's report of 10 visits per day does not come with complete details, then the company
                                    can fire you without any notice. </li>
                                <li>If any employee does not follow the timing office rules and company's business performance, the company
                                    can fire him without any notice on the basis of his records. </li>
                                <li>I accept that if I am found wrong in any of the four points given above, the company may fire me and I will
                                    not take any action. </li>
                            </ol>
                            {/* </li> */}
                        </ol>
                        {/* <ul>
                            <li>
                                Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.
                            </li>
                            <li>
                                We will collect and use of personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.
                            </li>
                            <li>
                                We will only retain personal information as long as necessary for the fulfillment of those purposes.
                            </li>
                            <li>
                                We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.
                            </li>
                            <li>
                                Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.
                            </li>
                            <li>
                                We will protect personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.
                            </li>
                            <li>
                                We will make readily available to customers information about our policies and practices relating to the management of personal information.
                            </li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <Information />
        </>
    )
}
