import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';


import Inquiry from '../../component/Inquiry';
import Loaction from '../../component/Loaction';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
export default function PrintingandPOSMSolutions() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        color: 'white'
    }
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading' style={style}>Printing and POSM Solutions</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" style={style}>Home</Link></li>
                        <li className='star'><i class="fa-solid fa-star-of-life" style={style}></i></li>
                        <li className="breadcrumb-item active" aria-current="page" style={style}>Printing and POSM Solutions</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'><i class="fa-solid fa-star-of-life"></i> OUR SERVICES</h3>
                                <h2 className='heading'>Printing and POSM Solutions</h2>
                                <p className=''>
                                    <strong>High-quality printing services for various marketing collateral to support promotional activities and campaigns.</strong>
                                </p>
                                <p>In a world inundated with digital noise, printed materials offer a tangible and impactful way to cut through the clutter and make a lasting impression. At Brand Street Integrated, our printing and point-of-sale materials (POSM) solutions are designed to elevate brand visibility and drive sales at the point of purchase. From eye-catching posters and banners to custom packaging and display stands, our printing capabilities are second to none. We take pride in delivering high-quality printed materials that not only showcase the essence of the brand but also create memorable experiences for consumers. Whether it's a small-scale print run or a large-scale POSM rollout, our team is dedicated to delivering excellence at every step of the process, from design and production to distribution and installation.
                                </p>

                                <h3 className='sub-heading'>This is how we work</h3>
                                <p>At our printing and point-of-sale materials (POSM) solutions are meticulously crafted to enhance brand visibility and drive sales. We work closely with our clients to understand their branding objectives and target audience, ensuring that every printed material and POSM reflects their brand identity and messaging effectively. Utilizing state-of-the-art printing technology and high-quality materials, we deliver solutions that not only captivate attention but also leave a lasting impression on consumers at the point of purchase. From eye-catching posters and banners to customized displays and promotional materials, our printing and POSM solutions are designed to elevate your brand presence and maximize ROI.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ServiceInclude />
                        </div>
                    </div>
                </div>
            </section>

            <Whychooseus />

            {/* Contact Us */}

            <Inquiry />
            {/* Country Image section */}
            <Loaction />
        </>
    )
}
