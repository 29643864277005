// import React, { useEffect, useState } from 'react'
// import Img from '../assets/images/lamp.svg'
// import Img1 from '../assets/images/lamp-withlight.svg'
// import { Link } from 'react-router-dom';
// export default function Header() {

//   const [isSticky, setIsSticky] = useState(false);
//   const [menuToggle, setMenuToggle] = useState(false);
//   const [subMenu, setSubMenu] = useState(false);
//   const [lampShow, setLampShow] = useState(false);


//   useEffect(() => {
//     const handleScroll = () => {
//       const offset = window.scrollY;
//       if (offset > 50) { // Adjust the value as needed
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <header className={isSticky ? 'sticky padding-left-right' : 'padding-left-right'}>
//       <div className='logo-section left_logo'>
//         <Link className="navbar-brand menu-logo" to="/">
//           <img src={require('../assets/images/logo.png')} alt='logo' />
//         </Link>
//       </div>
//       <div className='header-right-section'>
//         <div className='logo-section'>
//           <Link className="navbar-brand menu-logo" to="/">
//             <img src={require('../assets/images/logo-w.png')} alt='logo' />
//           </Link>
//         </div>
//         <div className='menu' onClick={() => setMenuToggle(true)}>
//           {/* <p>MENU</p> */}
//           <div className={`menu-bar ${menuToggle ? 'active' : ''}`}>
//             <span></span>
//             <span></span>
//           </div>
//         </div>
//       </div>
//       <div className={`mobile-menu ${menuToggle ? 'active' : ''}`}>
//         <button className='close-button' onClick={() => setMenuToggle(false)}><i class="fa-regular fa-circle-xmark"></i></button>
//         <div className='mobile-map-img'>
//           <img src={require('../assets/images/map.png')} alt='map' />
//         </div>
//         <div className='row'>
//           <div className='col-lg-6 col-md-6 colum-menu'>
//             <div className="contact-detail">
//               {/* <h5 className="section-title ">Contact us</h5> */}
//               <h2 className=''>Get in Touch</h2>
//               <div className='call-to-action'>
//                 <a href='tel:915757244789' className='wow fadeInUp'><i class="fa-solid fa-phone"></i> +91-575-724-4789</a>

//                 <a href="mailto:hi@thebrandstreet.co" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> hi@thebrandstreet.co</a>
//               </div>
//               <div className='social_media'>
//                 <p>Follow us:</p>
//                 <ul>
//                   <li className='' data-wow-delay="0.1s">
//                     <a href="https://www.facebook.com/brandstreetintegrated/" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
//                   </li>
//                   <li className='' data-wow-delay="0.2s">
//                     <a href="https://www.instagram.com/brandstreetlive/?igsh=MWtwN2s2NGdrb3ltMA%3D%3D" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
//                   </li>
//                   <li className='' data-wow-delay="0.3s">
//                     <a href="https://www.linkedin.com/company/brandstreet-integrated?originalSubdomain=in" target='_blank' className="twitter"><i className="fa-brands fa-linkedin-in"></i></a>
//                   </li>
//                   <li className='' data-wow-delay="0.4s">
//                     <a href="https://twitter.com/BrandStreetInt1" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
//                   </li>
//                   <li className='' data-wow-delay="0.5s">
//                     <a href="https://www.youtube.com/channel/UCs9vDqDgv7CzMkZa6mJ1ZxA" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//           <div className='call-to-action call_to_action_on_mobile'>
//             <div className='top-call-to'>
//               <a href='tel:915757244789'><i class="fa-solid fa-phone"></i>+91-575-724-4789</a>
//               <a href="mailto:hi@thebrandstreet.co"><i class="fa-solid fa-envelope"></i>hi@thebrandstreet.co</a>
//             </div>
//             {/* <ul>
//               <li className=''>
//                 <a href="https://www.facebook.com/brandstreetintegrated/" target='_blank' className="fb"><i className="fa-brands fa-facebook"></i></a>
//               </li>
//               <li className=''>
//                 <a href="https://www.instagram.com/brandstreetlive/?igsh=MWtwN2s2NGdrb3ltMA%3D%3D" target='_blank' className="insta"><i className="fa-brands fa-instagram"></i></a>
//               </li>
//               <li className=''>
//                 <a href="https://www.linkedin.com/company/brandstreet-integrated?originalSubdomain=in" target='_blank' className="twitter"><i className="fa-brands fa-linkedin-in"></i></a>
//               </li>
//               <li className='' >
//                 <a href="https://twitter.com/BrandStreetInt1" target='_blank' className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
//               </li>
//               <li className='' >
//                 <a href="https://www.youtube.com/channel/UCs9vDqDgv7CzMkZa6mJ1ZxA" target='_blank' className="twitter"><i className="fa-brands fa-youtube"></i></a>
//               </li>
//             </ul> */}
//           </div>
//           <div className='col-lg-6 col-md-6 menu-img'>

//             <div className='menu-right-img'>
//               <ul className='mobile-menu-list'>
//                 <li><Link to='/services' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>Services</Link></li>
//                 <li><Link to='/about' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>About</Link></li>
//                 <li><Link to='/team' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>Team</Link></li>
//                 <li><Link to='/clients' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>Clients</Link></li>
//                 <li><Link to='/gallery' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>Life @ BSI</Link></li>
//                 <li><Link to='/career' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>Career</Link></li>
//                 <li><Link to='/news-updates' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>News & Updates</Link></li>
//                 <li><Link to='/contact-us' onMouseEnter={() => setLampShow(true)} onMouseLeave={() => setLampShow(false)} onClick={() => setMenuToggle(false)}>Contact</Link></li>

//                 {/* <li><a href='#'>Cunsumer</a></li> */}
//                 {/* <li className={`sub-menu ${subMenu ? 'active' : ''}`}>
//                   <p onClick={() => setSubMenu(!subMenu)}>Services {subMenu ? <i class="fa-solid fa-angle-down"></i> : <i class="fa-solid fa-angle-right"></i>}</p>
//                   <ul>
//                     <li><a href='#'>Trade Marketing</a></li>
//                     <li><a href='#'>Hinterland Marketing</a></li>
//                     <li><a href='#'>Research Planning & Designing</a></li>
//                     <li><a href='#'>Auditing Reviewing & Reporing</a></li>
//                   </ul>
//                 </li> */}
//                 {/* <div className='lamp_light'>
//                   <div className='lamp-b'></div>
//               </div> */}
//               </ul>

//               <div className='lamp_light_section'>
//                 {
//                   lampShow ? <img className='light-img' src={require('../assets/images/lamp-1.png')} /> : <img className='light-img' src={require('../assets/images/lamp.png')} />
//                 }


//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>

//   )
// }


import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);



  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo" style={{ fontFamily: 'Algerian' }}>
            <img src={require('../assets/images/logo-ambika.png')} alt="ambika-maxx" />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
              >
                About
              </NavLink>
            </li>
            <li>
              <div class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><b>Products</b></a>
                <div class="dropdown-menu m-0 bg-secondary rounded-0">
                  <Link to="/wall-putty" class="dropdown-item">Wall Putty</Link>
                  <Link to="/decorative-white-cement" class="dropdown-item">Decorative White Cement</Link>
                  <Link to="/grout" class="dropdown-item">Grout</Link>
                  <Link to="/tiles-adhesive" class="dropdown-item">Tiles Adhesive</Link>
                  <Link to="/gypsum-plaster-pop" class="dropdown-item">Gypsum Plaster (POP)</Link>
                  <Link to="/water-brand-paint" class="dropdown-item">All Types of Water Based Paint</Link>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/gallery"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Gallery
              </NavLink>
            </li>
            <li>
              <div class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><b>Apply</b></a>
                <div class="dropdown-menu m-0 bg-secondary rounded-0">
                  <Link to="/employee" class="dropdown-item">Employee</Link>
                  <Link to="/distributorship" class="dropdown-item">Distributorship</Link>
                </div>
              </div>
            </li>&nbsp;&nbsp;
            <li className="nav-item">
              <Link to='/contact-us'><button type="button" class="btn btn-danger"> Contact Us  &nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></button></Link>
            </li><br />
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </ div>
  );
}

export default NavBar;
