import React from 'react'
import { Link } from 'react-router-dom'

export default function FeaturedProject() {
    return (
        <section className='bottom padding-left-right featured-section'>
            <div className='header-title mb-40px'>
                <div>
                    {/* <h6 className='sub-heading wow fadeInUp'>AWESOME PORTFOLIO</h6> */}
                    {/* <h2 className='heading wow fadeInUp'>Our World of Building Excellence</h2>
                    <p style={{ textAlign: 'center' }}>Meet Our Team, Explore Our Projects, and Discover Our Products</p> */}
                    {/* <center><div className="blue-line"></div></center> */}
                </div>
                {/* <Link to='/gallery' className='wow fadeInUp button_effect'><div className='line_border'></div>Explore All Works <i class="fa-solid fa-arrow-right"></i></Link> */}
            </div>

            <div class="marquee left">
                <h2 className='heading wow fadeInUp'>Client Location</h2>
                <div class="marquee-content">
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>

                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
            <h2 className='heading wow fadeInUp'>Special Offer</h2>
            <div class="marquee right">
                <div class="marquee-content">
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash </span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>

                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-1.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                    <div class="marquee-item">
                        <li class="left1">
                            <a href="../Home/newpaidservice?VenId=VI0000241" target="_blank">
                                <table className='table table-striped'>
                                    <tbody><tr>
                                        <td rowspan="4">
                                            <img src={require('../assets/images/projects/p-2.jpg')} style={{ width: '200px', height: '200px' }} alt="" />
                                        </td>
                                        <td>
                                            <span>White Wash</span>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td><span>Ambika Maxx India</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Gomtinagar </span></td>
                                        </tr>
                                        <tr>
                                            <td><span>Lucknow</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </section>
    )
}
