import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';

export default function Loaction() {
    return (
        <section>
            <div className='country-image'>

                <Swiper
                    slidesPerView={1.5}
                    spaceBetween={0}
                    navigation={false}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        },
                        768: {
                            slidesPerView: 5.8,
                            spaceBetween: 0,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 0,
                        },
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}

                    modules={[Autoplay]}

                    className="loactionSwiper"
                >
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/gurugram.jpg')} alt='gurugram' />
                            <div className='country-icons'>
                                <h3>Gurugram</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/delhi.jpg')} alt='delhi' />
                            <div className='country-icons'>
                                <h3>Delhi</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/mumbai.jpg')} alt='mumbai' />
                            <div className='country-icons'>
                                <h3>Mumbai</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/pune.jpg')} alt='pune' />
                            <div className='country-icons'>
                                <h3>Pune</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/kolkata.jpg')} alt='kolkata' />
                            <div className='country-icons'>
                                <h3>Kolkata</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/hyderabad.jpg')} alt='hyderabad' />
                            <div className='country-icons'>
                                <h3>Hyderabad</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/banglore.jpg')} alt='banglore' />
                            <div className='country-icons'>
                                <h3>Bangalore</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/chennai.jpg')} alt='chennai' />
                            <div className='country-icons'>
                                <h3>Chennai</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='country1'>
                            <img src={require('../assets/images/img-city/coimbatore.jpg')} alt='coimbatore' />
                            <div className='country-icons'>
                                <h3>Coimbatore</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                </Swiper>
            </div>
        </section>
    )
}
